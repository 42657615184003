import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class ERP extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"School Management"}
          titleDesc={
            "Fuse Classroom brings school management system (ERP) an AI platform to provide effective and scalable operations in school's administrative tasks."
          }
          titleKeywords={
            "erp, school management system, school management software, school bus app, school management, erp system, school erp"
          }
          fbDes={" Fuse Classroom’s School Management System (ERP) helps simplify school’s administrative tasks."}
          twitDesc={"Fuse Classroom’s School Management System (ERP) helps simplify school’s administrative tasks."}
          seoLink={"platform/school-management/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green overflow-hidden">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>School Management (ERP)</h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    School Management Systems is one AI platform provide effective and scalable operations. Continue
                    reading to see the different departmental dashboards that can be included in your School Management
                    system.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("ERP_Top_Request_Demo")}
                      state={{ page: "ERP" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="fluid-left d-none d-md-block mt-3 mb-3">
                  <img src="/images/platform/erp/erp.webp" alt="School Management" width="640" height="auto" />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* ERP */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <div className="my-2 text-center title">ERP</div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Academic-Programs")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/academic.svg" alt="Academic Programs" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Academic Programs</Card.Title>
                      <div className="small">
                        Provides an overview of the classes being offered and their related metrics.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Transportation")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/transportation.svg" alt="Transportation" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Transportation</Card.Title>
                      <div className="small">Manage and track bus schedules.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Accounting")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/accounting.svg" alt="Accounting" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Accounting</Card.Title>
                      <div className="small">Optimizes your school’s financial planning and payment processes.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Human-Resources")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/human-resources.svg"
                          alt="Human Resources"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Human Resources</Card.Title>
                      <div className="small">Allows you to recruit and retain the best employees. </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Facility-Management")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/facility.svg"
                          alt="Facility Management"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Facility Management</Card.Title>
                      <div className="small">Provides an overview of the school’s operational components. </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Career-Services")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/career.svg" alt="Career Services" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Career Services</Card.Title>
                      <div className="small">
                        Provides confidential and sound career advice based on individual student needs.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* ERP End */}

        {/* Academic Programs */}
        <section id="Academic-Programs" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/erp/academics.webp"
                  alt="Academic Programs"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/academic.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Academic Programs</h2>
                  <div className="h5 text-black font-weight-normal">
                    The Academic Programs feature gives administrators an overview of the classes being offered and
                    related metrics. The associated dashboard includes insights into student grades, student engagement,
                    the number of students enrolled in each class, and more.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Academic Programs end */}

        {/* Transportation */}
        <section id="Transportation" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/erp/transportation.webp"
                  alt="Transportation"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/transportation.svg" alt="Accounting" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Transportation</h2>
                  <div className="h5 text-black font-weight-normal">
                    The transportation app gives administrators and students the ability to manage and track bus
                    schedules and receive notifications in the event of delays or schedule changes.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Accounting end */}

        {/* Human Resources */}
        <section id="Human-Resources" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/erp/hr.webp"
                  alt="Human Resources"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/human-resources.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Human Resources</h2>
                  <div className="h5 text-black font-weight-normal">
                    This feature allows HR personnel to recruit and retain the best school employees. Administrators can
                    also see how effectively instructors are teaching online classes.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Human Resources end) */}

        {/* Facility Management */}
        <section className="py-5 bg-80 left" id="Facility-Management">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/erp/facility.webp"
                  alt="Facility Management"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/facility.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Facility Management</h2>
                  <div className="h5 text-black font-weight-normal">
                    Administrators can see an overview of the school’s various operational components, such as tracking
                    classroom availability, requests for supplies, and cafeteria menus. Associated dashboards are
                    created to visualize the information needed to run facilities.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Facility Management end */}

        {/* Accounting */}
        <section id="Accounting" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/erp/accounting.webp"
                  alt="Accounting"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/accounting.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Accounting</h2>
                  <div className="h5 text-black font-weight-normal">
                    With the Accounting feature, it's never been easier to optimize financial planning and payment
                    processes. The Accounting dashboard gives access to user-friendly analytics helping bring the most
                    important data to light for monitoring and managing budgets, analyzing trends, and other important
                    accounting activities.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Transporatation end) */}

        {/* Career Services */}
        <section className="py-5 bg-80 left" id="Career-Services">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/erp/career.webp"
                  alt="Career Services"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/career.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Career Services</h2>
                  <div className="h5 text-black font-weight-normal">
                    The Career Services provides students and administrators dashboards including job boards, hiring
                    trends, and salary information. All the tools needed to provide students career advice are included
                    here.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Career Services end */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("ERP_Bottom_Request_Demo")}
                    state={{ page: "ERP" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default ERP
